"use client";

import { useEffect, useRef, useState } from "react";
import { usePathname, useRouter, useSearchParams } from "next/navigation";

import { cn } from "@/lib/utils";
import { Search } from "lucide-react";

type SearchInputProps = {
  className?: string;
  placeholder?: string;
};

// NOTE: 本来はAPIを叩いてサジェストを取得すべきだが、初回リリースでは簡易実装＆リクエスト数削減のため、固定のサジェストを表示
const INITIAL_SUGEESTIONS = [
  "iPhone 15 Pro Max",
  "iPhone 15 Pro",
  "iPhone 15 Plus",
  "iPhone 15",
  "iPhone 14 Pro Max",
  "iPhone 14 Pro",
  "iPhone 14 Plus",
  "iPhone 14",
  "iPhone 13 Pro Max",
  "iPhone 13 Pro",
  "iPhone 13 mini",
  "iPhone 13",
  "iPhone 12 Pro Max",
  "iPhone 12 Pro",
  "iPhone 12 mini",
  "iPhone 12",
  "iPhone 11 Pro Max",
  "iPhone 11 Pro",
  "iPhone 11",
  "iPhone XS Max",
  "iPhone XS",
  "iPhone XR",
  "iPhone X",
  "iPhone SE (第3世代)",
  "iPhone SE (第2世代)",
  "iPhone SE (第1世代)",
  "iPhone 8 Plus",
  "iPhone 8",
  "iPhone 7 Plus",
  "iPhone 7",
  "iPhone 6s Plus",
  "iPhone 6s",
  "iPhone 6 Plus",
  "iPhone 6",
  "iPhone 5s",
  "iPhone 5c",
  "iPhone 5",
  "iPhone 4s",
  "iPhone 4",
  "iPhone 3GS",
  "iPhone 3G",
  "Xperia XZ3",
  "Xperia XZ2",
  "Xperia XZ1",
  "Xperia Ace III",
  "Xperia Ace II",
  "Xperia 5 IV",
  "Xperia 5 II",
  "Xperia 5",
  "Xperia 10 IV",
  "Xperia 10 III",
  "Xperia 10 II",
  "Xperia 1 III",
  "Xperia 1 II",
  "Xperia 1",
  "Xiaomi 13T",
  "TORQUE G03 HELLY HANSEN LIMITED",
  "Redmi Note 10T",
  "Redmi Note 10 JE",
  "Redmi 12 5G",
  "Rakuten Mini",
  "Rakuten Hand 5G",
  "Rakuten Hand",
  "OPPO Reno 9A",
  "OPPO Reno 7A",
  "OPPO Reno 5A",
  "OPPO Reno 3A",
  "OPPO Reno 10 Pro 5G",
  "OPPO A79 5G",
  "OPPO A73",
  "OPPO A55s 5G",
  "OPPO A54 5G",
  "Moto g53y 5G",
  "Libero 5G IV",
  "Libero 5G III",
  "Libero 5G II",
  "HUAWEI P30 lite",
  "HUAWEI P20 lite",
  "Google Pixel 8",
  "Google Pixel 7a",
  "Google Pixel 7 Pro",
  "Google Pixel 7",
  "Google Pixel 6a",
  "Google Pixel 3a",
  "Galaxy S9",
  "Galaxy S23 Ultra",
  "Galaxy S22",
  "Galaxy S21 5G",
  "Galaxy S20 5G",
  "Galaxy S10",
  "Galaxy A53 5G",
  "Galaxy A41",
  "Galaxy A22 5G",
  "Galaxy A21",
  "Galaxy A20",
  "arrows We",
  "AQUOS wish3",
  "AQUOS wish 2",
  "AQUOS wish",
  "AQUOS sense6",
  "AQUOS sense4",
  "AQUOS sense3 basic",
  "AQUOS sense3",
  "AQUOS sense2",
  "AQUOS sense 5G",
  "AQUOS R5G",
];

const SearchInput = ({ className, placeholder }: SearchInputProps) => {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const router = useRouter();

  const inputRef = useRef<HTMLInputElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [composing, setComposing] = useState<boolean>(false);
  const [inputText, setInputText] = useState<string>("");
  const [selectIndex, setSelectIndex] = useState<number>(-1);
  const [suggestions, setSuggestions] = useState<string[]>(INITIAL_SUGEESTIONS.slice(0, 5));

  useEffect(() => {
    reset();

    const keyword = searchParams.get("keyword");
    setInputText(keyword || "");
  }, [pathname, searchParams]);

  const reset = () => {
    setInputText("");
    setSelectIndex(-1);
    setIsOpen(false);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputText(e.target.value);
    setSelectIndex(-1);
    setSuggestions(
      INITIAL_SUGEESTIONS.filter((suggestion) =>
        suggestion.toLowerCase().includes(e.target.value.toLowerCase()),
      ).slice(0, 5), // 上位5件のみに制限,
    );
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    switch (e.key) {
      case "ArrowUp":
        setSelectIndex((prev) => {
          const newIndex = (prev - 1 + suggestions.length) % suggestions.length;
          setInputText(suggestions[newIndex]);
          return newIndex;
        });
        inputRef.current?.focus();
        inputRef.current?.setSelectionRange(inputText.length, inputText.length);
        break;
      case "ArrowDown":
        setSelectIndex((prev) => {
          const newIndex = (prev + 1) % suggestions.length;
          setInputText(suggestions[newIndex]);
          return newIndex;
        });
        inputRef.current?.focus();
        inputRef.current?.setSelectionRange(inputText.length, inputText.length);
        break;
      case "Enter":
        if (composing) {
          break;
        }
        setIsOpen(false);
        setSelectIndex(-1);
        inputRef.current?.blur();
        router.push(inputText ? `/search?keyword=${inputText}` : "/search");
        break;
      case "Escape":
        setIsOpen(false);
        setSelectIndex(-1);
        inputRef.current?.blur();
        break;
      default:
        break;
    }
  };

  const handleSelectItem = (e: React.MouseEvent<HTMLLIElement>) => {
    setInputText(e.currentTarget.getAttribute("value") || "");
    setIsOpen(false);
    const value = e.currentTarget.getAttribute("value");
    router.push(value ? `/search?keyword=${value}` : "/search");
  };

  const handleBlur = () => {
    setSelectIndex(-1);
    setIsOpen(false);
  };

  return (
    <div className={cn("rounded", className)}>
      <div className="relative  w-full">
        <div className="flex items-center">
          <Search className="absolute left-2 z-10 bg-white hover:cursor-pointer" />
          <input
            type="search"
            id="search"
            className="w-full rounded-full border-y border-r border-black bg-white py-2 pl-10 pr-14 placeholder:text-xs"
            placeholder={placeholder || ""}
            value={inputText}
            ref={inputRef}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            onCompositionStart={() => setComposing(true)}
            onCompositionEnd={() => setComposing(false)}
            onFocus={() => setIsOpen(true)}
            onBlur={handleBlur}
          />
          <button
            className="absolute right-0 z-10 h-full rounded-r-full border border-black bg-primary-light px-3 text-xs"
            onClick={() => router.push(inputText ? `/search?keyword=${inputText}` : "/search")}
          >
            検索
          </button>
        </div>

        {isOpen && (
          <ul className="absolute w-full overflow-visible bg-white shadow-lg">
            {suggestions.map((suggestion, i) => {
              return (
                <li
                  key={suggestion}
                  className={cn("text-md rounded px-2 py-1", {
                    "bg-blue-200": selectIndex === i,
                  })}
                  value={suggestion}
                  onMouseDown={handleSelectItem}
                  onMouseEnter={() => setSelectIndex(i)}
                >
                  {suggestion}
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </div>
  );
};

export default SearchInput;
